;(function () {
    'use strict';

    var masthead = document.querySelector('.js-masthead');
    if (typeof masthead !== 'undefined' && masthead !== null) {
        var headroom = new Headroom(
            masthead,
            {
                'tolerance': 5,
                onNotTop: function() {
                    var masthead = document.querySelector('.js-masthead');
                    var mainMenus = masthead.querySelectorAll('.js-main-menu');
                    var searchToggle = masthead.querySelector('.js-search-toggle');
                    var phone = masthead.querySelector('.js-phone');
                    var menuToggle = masthead.querySelector('.js-menu-toggle');

                    if (typeof masthead !== 'undefined' && masthead !== null && masthead.classList) {
                        if (!masthead.classList.contains('-theme-invert')) {
                            masthead.classList.add('-theme-invert');
                        }
                    }

                    if (typeof searchToggle !== 'undefined' && searchToggle !== null && searchToggle.classList) {
                        if (searchToggle.classList.contains('-theme-white')) {
                            searchToggle.classList.remove('-theme-white');
                        }
                        if (!searchToggle.classList.contains('-theme-turquoise')) {
                            searchToggle.classList.add('-theme-turquoise');
                        }
                    }

                    if (typeof phone !== 'undefined' && phone !== null && phone.classList) {
                        if (phone.classList.contains('-theme-white')) {
                            phone.classList.remove('-theme-white');
                        }
                        if (!phone.classList.contains('-theme-turquoise')) {
                            phone.classList.add('-theme-turquoise');
                        }
                    }

                    if (typeof menuToggle !== 'undefined' && menuToggle !== null && menuToggle.classList) {
                        if (menuToggle.classList.contains('-theme-white')) {
                            menuToggle.classList.remove('-theme-white');
                        }
                        if (!menuToggle.classList.contains('-theme-turquoise')) {
                            menuToggle.classList.add('-theme-turquoise');
                        }
                    }

                    Array.prototype.forEach.call(mainMenus, function(mainMenu, i) {
                        if (mainMenu.classList) {
                            if (!mainMenu.classList.contains('-theme-invert')) {
                                mainMenu.classList.add('-theme-invert');
                            }
                        }
                    });
                },
                onTop: function() {
                    var masthead = document.querySelector('.js-masthead');
                    var searchBar = document.querySelector('.js-search-bar');
                    var mainMenus = masthead.querySelectorAll('.js-main-menu');
                    var searchToggle = masthead.querySelector('.js-search-toggle');
                    var phone = masthead.querySelector('.js-phone');
                    var menuToggle = masthead.querySelector('.js-menu-toggle');
                    var isSearchBarActive = false;
                    var isMenuToggleOpen = false;

                    if (searchBar.classList) {
                        isSearchBarActive = searchBar.classList.contains('is-active');
                    }

                    if (menuToggle.classList) {
                        isMenuToggleOpen = menuToggle.classList.contains('is-open');
                    }

                    if (isSearchBarActive || isMenuToggleOpen) {
                        return;
                    }

                    if (typeof masthead !== 'undefined' && masthead !== null && masthead.classList) {
                        if (masthead.classList.contains('-theme-invert')) {
                            masthead.classList.remove('-theme-invert');
                        }
                    }

                    if (typeof searchToggle !== 'undefined' && searchToggle !== null && searchToggle.classList) {
                        if (searchToggle.classList.contains('-theme-turquoise')) {
                            searchToggle.classList.remove('-theme-turquoise');
                        }
                        if (!searchToggle.classList.contains('-theme-white')) {
                            searchToggle.classList.add('-theme-white');
                        }
                    }

                    if (typeof phone !== 'undefined' && phone !== null && phone.classList) {
                        if (phone.classList.contains('-theme-turquoise')) {
                            phone.classList.remove('-theme-turquoise');
                        }
                        if (!phone.classList.contains('-theme-white')) {
                            phone.classList.add('-theme-white');
                        }
                    }

                    if (typeof menuToggle !== 'undefined' && menuToggle !== null && menuToggle.classList) {
                        if (menuToggle.classList.contains('-theme-turquoise')) {
                            menuToggle.classList.remove('-theme-turquoise');
                        }
                        if (!menuToggle.classList.contains('-theme-white')) {
                            menuToggle.classList.add('-theme-white');
                        }
                    }

                    Array.prototype.forEach.call(mainMenus, function(mainMenu, i) {
                        if (mainMenu.classList) {
                            if (mainMenu.classList.contains('-theme-invert')) {
                                mainMenu.classList.remove('-theme-invert');
                            }
                        }
                    });
                }
            }
        );
        headroom.init();
    }
}());

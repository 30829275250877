var smoothScrollTo = function(element, offset) {

    if (!element) {
        return null;
    }

    var top = smoothScrollElemYDistance(element);

    if (!offset && offset !== 0) {

        offset = 0;

        var viewportWidth = window.innerWidth;
        var currentScrollY = window.scrollY;
        if (typeof currentScrollY === 'undefined' || currentScrollY === null) {
            currentScrollY = window.pageYOffset;
        }

        var siteHeader = document.querySelector('.js-site-header');
        if (typeof siteHeader !== 'undefined' && siteHeader !== null) {
            offset = siteHeader.offsetHeight;

            var siteHeaderHeadroomOffset = siteHeader.offsetHeight - 30;
            if (viewportWidth > 1023 && currentScrollY < siteHeaderHeadroomOffset) {
                offset = offset - 30;
            }
        }

        var searchBar = document.querySelector('.js-search-bar.is-active');
        if (typeof searchBar !== 'undefined' && searchBar !== null) {
            offset = offset + searchBar.offsetHeight;
        }

        if (viewportWidth > 767) {
            var summaryBar = document.querySelector('.js-tour-summary');
            if (typeof summaryBar !== 'undefined' && summaryBar !== null) {

                var tourSummaryOffset = document.querySelector('.js-tour-summary-offset');
                var tourSummaryOffsetTop = smoothScrollElemYDistance(tourSummaryOffset);

                if (top > tourSummaryOffsetTop) {
                    offset = offset + summaryBar.offsetHeight;
                }
            }
        }
    }

    top = top - offset;
    window.scrollTo({ top: top, left: 0, behavior: 'smooth'});
};

var smoothScrollElemYDistance = function(element) {
    var location = 0;
    if (element.offsetParent) {
        do {
            location += element.offsetTop;
            element = element.offsetParent;
        } while (element);
    }
    return location >= 0 ? location : 0;
};

var smoothScrollToHashTarget = function(hash) {
    if (hash[0] === '#') {
        hash = hash.slice(1);
    }
    var target = document.getElementById(hash);
    if (typeof target === 'undefined' && target === null) {
        target = document.querySelector('[name="' + hash + '"]');
    }
    if (typeof target !== 'undefined' && target !== null) {
        smoothScrollTo(target);
    }
};

;(function () {
    'use strict';

    var scrollAnchors = document.querySelectorAll('a[href*="#"]:not([href="#"]):not(.js-scroll-ignore)');
    Array.prototype.forEach.call(scrollAnchors, function(anchor, i) {
        onClickOrTap(anchor, function(e) {
            e.preventDefault();
            var hash = null;
            Array.prototype.forEach.call(e.path, function(element, i) {
                if (hash === null && typeof element.hash !== 'undefined' && element.hash !== null) {
                    hash = element.hash;
                }
            });
            if (hash !== null) {
                smoothScrollToHashTarget(hash);
            }
        }) ;
    });

    ready(function() {
        setTimeout(function() {
            var hash = location.hash;
            if (hash.length) {
                smoothScrollToHashTarget(hash);
                return false;
            }
        }, 1);
    });

}());

;(function () {
    'use strict';

    var modalInits = document.querySelectorAll('.js-modal-init');
    if (modalInits.length > 0) {

        Array.prototype.forEach.call(modalInits, function(modalInit, i) {

            var modalSelector = modalInit.getAttribute('data-modal-target-id');
            if (typeof modalSelector !== 'undefined' && modalSelector !== null) {

                var modalElement = document.getElementById(modalSelector);
                if (typeof modalElement !== 'undefined' && modalElement !== null) {

                    onClickOrTap(modalInit, function(e) {
                        e.preventDefault();
                        var modalSelector = modalInit.getAttribute('data-modal-target-id');
                        var modalElement = document.getElementById(modalSelector);
                        var modalOpenCallback = modalElement.getAttribute('data-modal-open-callback');
                        var modalCloseCallback = modalElement.getAttribute('data-modal-close-callback');
                        var resetContent = modalElement.getAttribute('data-modal-reset');
                        var modal = new A11yDialog(modalElement, 'body');

                        modal.show();

                        if (typeof modalOpenCallback !== 'undefined' && modalOpenCallback !== null) {
                            window[modalOpenCallback].call(modalElement);
                        }

                        var body = document.querySelector('body');
                        if (body.classList && !body.classList.contains('has-modal-open')) {
                            body.classList.add('has-modal-open');
                        }

                        if (typeof resetContent !== 'undefined' && resetContent !== null) {

                            var modalTitle = modalInit.getAttribute('data-modal-title');
                            if (typeof modalTitle !== 'undefined' && modalTitle !== null) {
                                modalElement.querySelector('.js-modal-title').innerText = modalTitle;
                            }

                            var modalContentSelector = modalInit.getAttribute('data-modal-content');
                            if (typeof modalContentSelector !== 'undefined' && modalContentSelector !== null) {
                                var modalContentElement = document.querySelector(modalContentSelector);
                                if (typeof modalContentElement !== 'undefined' && modalContentElement !== null) {
                                    var modalContentHTML = modalContentElement.outerHTML;
                                    modalElement.querySelector('.js-modal-content').innerHTML = modalContentHTML;
                                }
                            }

                            modal.on('hide', function(dialogEl, event) {
                                dialogEl.querySelector('.js-modal-title').innerText = '';
                                dialogEl.querySelector('.js-modal-content').innerHTML = '';
                                var body = document.querySelector('body');
                                if (body.classList && body.classList.contains('has-modal-open')) {
                                    body.classList.remove('has-modal-open');
                                }
                                if (typeof modalCloseCallback !== 'undefined' && modalCloseCallback !== null) {
                                    window[modalCloseCallback].call(dialogEl);
                                }
                            });
                        }
                        else {
                            modal.on('hide', function(dialogEl, event) {
                                var body = document.querySelector('body');
                                if (body.classList && body.classList.contains('has-modal-open')) {
                                    body.classList.remove('has-modal-open');
                                }
                                if (typeof modalCloseCallback !== 'undefined' && modalCloseCallback !== null) {
                                    window[modalCloseCallback].call(dialogEl);
                                }
                            });
                        }
                    });
                }
            }
        });
    }
}());

///////////////////// BROCHURE DOWNLOAD MODAL \\\\\\\\\\\\\\\\\\\\\\\\\

var brochureDownloadModalTriggerButton = document.querySelector(".download-button");
var brochureDownloadModal = document.querySelector(".brochure-download-modal");
var brochureDownloadCloseButton = document.querySelector(".brochure-modal-close");
var skip = document.querySelector(".skip-div");
var form = document.querySelector(".s-Form--brochure-download");
var downloadButton = document.querySelector(".hidden-download-button");
var downloadUrl = "";


if (brochureDownloadModalTriggerButton){
    brochureDownloadModalTriggerButton.addEventListener("click",function(){
        toggleBrochureDownloadModal();
        // var thisURL = $(this).closest('.brochure-box').find('.download-button').attr('href');

        var thisURL = downloadButton.getAttribute('href');
        
        sessionStorage.setItem('DLlink',thisURL);
        downloadUrl = sessionStorage.getItem('DLlink');
       // $('.skip-div').attr('src',thisURL);
    });
}

if (brochureDownloadCloseButton){
	brochureDownloadCloseButton.addEventListener("click", function(){
		toggleBrochureDownloadModal();
	});
}

if (brochureDownloadModal){
    brochureDownloadModal.addEventListener("click",function(event) {
        if(event.target.classList.contains("brochure-download-modal")){
            toggleBrochureDownloadModal();
        }
    });
}

if (skip) {
    skip.addEventListener("click",function(){
        toggleBrochureDownloadModal();
        window.open(downloadUrl);
    })
}

function toggleBrochureDownloadModal() {
    if(window.getComputedStyle(brochureDownloadModal).display==="flex"){
        brochureDownloadModal.style.display = "none";
        document.body.style.overflow = "auto";
    }
    else {
        brochureDownloadModal.style.display = "flex";
        document.body.style.overflow = "hidden";
    }
};

if (form){
    form.addEventListener("submit",function(){
        window.open(downloadUrl);
    })
};

///////////////////// BROCHURE ORDER MODAL \\\\\\\\\\\\\\\\\\\\\\\\\

var brochureOrderModalTriggerButton = document.querySelector(".printed-brochure-btn");
var brochureOrderModal = document.querySelector(".brochure-order-modal");
var brochureOrderCloseButton = document.querySelector(".brochure-order-modal-close");

// var form = document.querySelector(".s-Form--brochure-order");
// var orderButton = document.querySelector(".hidden-order-button");



if (brochureOrderModalTriggerButton){
    brochureOrderModalTriggerButton.addEventListener("click",function(){
        toggleBrochureOrderModal();

        // var thisURL = downloadButton.getAttribute('href');
        
        // sessionStorage.setItem('DLlink',thisURL);
        // downloadUrl = sessionStorage.getItem('DLlink');
       
    });
}

if (brochureOrderCloseButton){
	brochureOrderCloseButton.addEventListener("click", function(){
		toggleBrochureOrderModal();
	});
}

if (brochureOrderModal){
    brochureOrderModal.addEventListener("click",function(event) {
        if(event.target.classList.contains("brochure-order-modal")){
            toggleBrochureOrderModal();
        }
    });
}


function toggleBrochureOrderModal() {
    if(window.getComputedStyle(brochureOrderModal).display==="flex"){
        brochureOrderModal.style.display = "none";
        document.body.style.overflow = "auto";
    }
    else {
        brochureOrderModal.style.display = "flex";
        document.body.style.overflow = "hidden";
    }
};

// if (form){
//     form.addEventListener("submit",function(){
//         window.open(OrderUrl);
//     })
// };

;(function () {
    'use strict';

    var departures = document.querySelectorAll('.content-ul');
	Array.prototype.forEach.call(departures, function(departure, i) {

		onClickOrTap(departure, function(e) {

			
			var cart = getClosest(departure, ".modal-wrapper").querySelector(".modal2");
			var twinPrice = departure.getAttribute('data-departure-twin-price');
			var singlePrice = departure.getAttribute('data-departure-single-price');
			var tropicsLink = (departure.getAttribute('data-departure-tropics-link') + "&adults=2")
			var tropicsLinkSingle = departure.getAttribute('data-departure-tropics-link')
			var status = departure.getAttribute('data-departure-status');
			var departDate = departure.getAttribute('data-departure-depart-date');
			var departEndDate = departure.getAttribute("data-departure-end-date");
			var saveAmount = departure.getAttribute("data-saveamount");
			var TwinSaveAmount = departure.getAttribute("data-saveamount");
			var adultTwinWasPrice = departure.getAttribute("data-adulttwinwasprice");
			var adultSingleWasPrice = departure.getAttribute("data-adultsinglewasprice");
			var twinBox = document.querySelector("#Twin-Box")
			var data = {
				twinPrice: twinPrice,
				singlePrice: singlePrice,
				tropicsLink: tropicsLink,
				tropicsLinkSingle: tropicsLinkSingle,
				status: status,
				departDate: departDate,
				departEndDate: departEndDate,
				saveAmount: saveAmount,
				TwinSaveAmount: TwinSaveAmount,
				adultTwinWasPrice: adultTwinWasPrice,
				adultSingleWasPrice: adultSingleWasPrice
			};
			

			initPricingCart(cart, data);

			Array.prototype.forEach.call(departures, function(dep, i) {
				dep.classList.remove('is-active');
			});

			if (status === "available" || status === "onrequest") {
				togglePricingModal2()
				twinBox.classList.add("twin-box-selected")
				
			}

		});
	});

	var initPricingCart = function(cart, data) {

		var root = cart.querySelector('.modal2-root');
		var twinPrice = cart.querySelector('.modal2-twin');
		var singlePrice = cart.querySelector('.modal2-single');
		var addToCartLink = cart.querySelector('.checkout-button');
		var addToCartLinkSingle = cart.querySelector('.checkout-button-hidden');
		var departDate = cart.querySelector('.modal2-departs');
		var closeButton = cart.querySelector('.modal2-close');
		var departEndDate = cart.querySelector(".modal2-ends");
		// var saveAmount = cart.querySelector(".savedAmounts");
		var TwinSaveAmount = cart.querySelector(".savedAmount");
		var adultTwinWasPrice = cart.querySelector(".modal2-twin-was");
		var adultSingleWasPrice = cart.querySelector(".modal2-single-was");

		departDate.innerHTML = data.departDate;
		departEndDate.innerHTML = data.departEndDate;
		twinPrice.innerHTML = data.twinPrice;
		singlePrice.innerHTML = data.singlePrice;
		// saveAmount.innerHTML = data.saveAmount;
		// adultTwinWasPrice.innerHTML = data.adultTwinWasPrice;

		if (data.saveAmount >= 1){
			if (data.adultTwinWasPrice == "$"){
				adultTwinWasPrice.innerHTML = "";
				adultTwinWasPrice.classList.add("disappear");
			} else {
				adultTwinWasPrice.classList.remove("disappear");
				adultTwinWasPrice.innerHTML = 'Was <p>' + data.adultTwinWasPrice + '</p>';
				twinPrice.innerHTML = 'Now <p>' + data.twinPrice + '</p>';
			}
			
			if (data.adultSingleWasPrice == "$"){
				adultSingleWasPrice.innerHTML = "";
				adultSingleWasPrice.classList.add("disappear");
			} else {
				adultSingleWasPrice.classList.remove("disappear");
				adultSingleWasPrice.innerHTML = 'Was <p>' + data.adultSingleWasPrice + '</p>';
				singlePrice.innerHTML = 'Now <p>' + data.singlePrice + '</p>';
			}
		}
		


		// TwinSaveAmount.innerHTML = Number(data.TwinSaveAmount) + Number(data.TwinSaveAmount);

		if (data.status === 'available') {
			root.classList.add('is-bookable');
			addToCartLink.href = data.tropicsLink;
			addToCartLinkSingle.href = data.tropicsLinkSingle;
		}
		else {
			root.classList.remove('is-bookable');
			addToCartLink.href = '#';
		}

		root.classList.add('has-data');
		cart.classList.add('is-visible');

		onClickOrTap(closeButton, function(e) {
			e.preventDefault();
			resetPricingCart(cart);
		});
	};

	var resetPricingCart = function(cart) {

		var root = cart.querySelector('.modal2-root');
		var twinPrice = cart.querySelector('.modal2-twin');
		var twinFromPrice = twinPrice.getAttribute('data-pricing-cart-twin');
		var singlePrice = cart.querySelector('.modal2-single');
		var singleFromPrice = singlePrice.getAttribute('data-pricing-cart-single');
		// var addToCartLink = cart.querySelector('.js-pricing-cart-addToCart');

		cart.classList.remove('is-visible');

		root.classList.remove('has-data');
		root.classList.remove('is-bookable');

		twinPrice.innerHTML = twinFromPrice;
		singlePrice.innerHTML = singleFromPrice;

		// addToCartLink.setAttribute('href', '#');

		Array.prototype.forEach.call(departures, function(departure, i) {
			departure.classList.remove('is-active');
		});
	};


}());


var pricingModalTriggerButton = document.querySelector(".pricing-modal-button")
var pricingModalTriggerButton2 = document.querySelector(".js-modal-init")
// var pricingModalTriggerButton3 = document.querySelector(".TourRate")
var checkoutButton = document.querySelector(".checkout-button")





var pricingModal1 = document.querySelector(".modal1")
var pricingModal2 = document.querySelector(".modal2")

var twinBox = document.querySelector("#Twin-Box")
var singleBox = document.querySelector("#Single-Box")

var pricingModalCloseButton = document.querySelector(".modal1-close")
var pricingModalCloseButton2 = document.querySelector(".modal2-close")
var pricingModalCloseButton3 = document.querySelector(".change-button")
var pricingModalCloseButton4 = document.querySelector(".modal2-back")



var tourRates = document.querySelectorAll('.TourRate');
	Array.prototype.forEach.call(tourRates, function(tourRate, i) {

		onClickOrTap(tourRate, function(e) {
			togglePricingModal()
		})
	});
	


if (pricingModalTriggerButton) {
	pricingModalTriggerButton.addEventListener("click", function(){
		togglePricingModal()
	});
}

if (pricingModalTriggerButton2){
	pricingModalTriggerButton2.addEventListener("click", function(){
		togglePricingModal()
	});
}

// pricingModalTriggerButton3.addEventListener("click", function(){
// 	togglePricingModal()
// });

if (pricingModalCloseButton){
	pricingModalCloseButton.addEventListener("click", function(){
		togglePricingModal()
	});
}

if (pricingModalCloseButton2){
	pricingModalCloseButton2.addEventListener("click", function(){
		togglePricingModal2()
	});
}

if (pricingModalCloseButton3){
	pricingModalCloseButton3.addEventListener("click", function(){
		togglePricingModal2()
	});
}

if (pricingModalCloseButton4){
	pricingModalCloseButton4.addEventListener("click", function(){
		togglePricingModal2()
	});
}



function twinPriceSelector() {
    document.querySelector(".accommodation-select").textContent = "TWIN ROOM"
	document.querySelector(".twin-prices-modal2").style.display = "flex"
    document.querySelector(".modal2-twin-was").style.display = "flex"
    document.querySelector(".single-prices-modal2").style.display = "none"
    document.querySelector(".modal2-twin").style.display = "flex"
	document.querySelector(".proceed-to-checkout-hidden").style.display = "none"
	document.querySelector(".proceed-to-checkout").style.display = "block"

	// document.querySelector(".twin-prices").classList.add("selected")
}

function singlePriceSelector() {
    document.querySelector(".accommodation-select").textContent = "SINGLE ROOM"
    document.querySelector(".single-prices-modal2").style.display = "flex"
    document.querySelector(".twin-prices-modal2").style.display = "none"
    document.querySelector(".single-prices-modal2").style.display = "block"
	twinBox.classList.remove("twin-box-selected")
	document.querySelector(".proceed-to-checkout-hidden").style.display = "block"
	document.querySelector(".proceed-to-checkout").style.display = "none"
	



}

if (twinBox){
	twinBox.addEventListener("click", function() {
		twinPriceSelector()
	})
}

if (singleBox) {
	singleBox.addEventListener("click", function() {
		singlePriceSelector()
	})
}


if (pricingModal1){
	pricingModal1.addEventListener("click",function(event){
		if(event.target.classList.contains("modal1")){
			togglePricingModal()
		}
	})
}

if (pricingModal2){
	pricingModal2.addEventListener("click", function(event){
		if(event.target.classList.contains("modal2")){
			togglePricingModal2()
		}
	})
}

function togglePricingModal() {
    if(window.getComputedStyle(pricingModal1).display==="flex"){
        pricingModal1.style.display = "none";
    document.body.style.overflow = "auto";
    }
    else {
        pricingModal1.style.display = "flex";
    document.body.style.overflow = "hidden";
    }
}


function togglePricingModal2() {
    if(window.getComputedStyle(pricingModal2).display==="flex"){
        pricingModal2.style.display = "none";
        document.body.style.overflow = "auto";
    }
    else {
        pricingModal2.style.display = "flex";
        document.body.style.overflow = "hidden";
    }
    
}


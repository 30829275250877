;(function () {
    'use strict';

    var toggles = document.querySelectorAll('.js-search-toggle');
    var bar = document.querySelector('.js-search-bar');

    Array.prototype.forEach.call(toggles, function(toggle, i) {

        onClickOrTap(toggle, function(e) {
            if (bar.classList) {
                bar.classList.toggle('is-active');
            }

            var currentYPosition = window.scrollY;
            if (typeof currentYPosition === 'undefined' || currentYPosition === null) {
                currentYPosition = window.pageYOffset;
            }
            if (currentYPosition < 1) {
                window.scrollTo({top: 1, left: 0, behavior: 'smooth'});
            }
        });
    });
}());

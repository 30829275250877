;(function () {
    'use strict';

    ready(function() {

        var carousels = document.querySelectorAll('.js-carousel');
        Array.prototype.forEach.call(carousels, function(carousel, i) {

            var slides = carousel.querySelector('.js-carousel-slides');
            var prev = carousel.querySelector('.js-carousel-prev');
            var next = carousel.querySelector('.js-carousel-next');

            var mode = carousel.getAttribute('data-carousel-mode');
            if (typeof mode === 'undefined' || mode === null) {
                mode = 'gallery';
            }

            var speed = carousel.getAttribute('data-carousel-speed');
            if (typeof speed === 'undefined' || speed === null) {
                speed = 300;
            }
            else {
                speed = speed * 1;
            }

            var autoplay = carousel.hasAttribute('data-carousel-autoplay');

            if (typeof slides !== 'undefined' && slides !== null) {

                var options = {
                    container: slides,
                    mode: mode,
                    items: 1,
                    autoHeight: true,
                    nav: false,
                    controls: false,
                    autoplay: autoplay,
                    autoplayButtonOutput: false,
                    speed: speed
                };

                if (typeof prev !== 'undefined' && prev !== null) {
                    options['prevButton'] = prev;
                    options['controls'] = true;
                }
                else {
                    options['prevButton'] = false;
                }

                if (typeof next !== 'undefined' && next !== null) {
                    options['nextButton'] = next;
                    options['controls'] = true;
                }
                else {
                    options['nextButton'] = false;
                }

                var thisCarousel = tns(options);
            }
        });
    });

}());

;(function () {
    'use strict';

    var lightboxes = document.querySelectorAll('.js-lightbox');

    Array.prototype.forEach.call(lightboxes, function(lightbox, i) {

        var options = {
            selector: 'this',
            hash: false,
            download: false,
            youtubePlayerParams: {
                modestbranding: 0,
                showinfo: 0,
                rel: 0
            },
            vimeoPlayerParams: {
                byline : 0,
                portrait : 0,
                title: 0,
                autoplay: 0
            }
        };

        var imageData = lightbox.getAttribute('data-lightbox-image-data');
        if (typeof imageData !== 'undefined' && imageData !== null) {

            onClickOrTap(lightbox, function(e) {
                options['dynamic'] = true;
                options['dynamicEl'] = JSON.parse(imageData);
                lightGallery(lightbox, options);
            });
        }
        else {
            lightGallery(lightbox, options);
        }
    });
}());

;(function () {
    'use strict';

    ready(function() {

        var loadItems = window.siteEventsOnLoad;
        if (typeof loadItems !== 'undefined' && loadItems !== null) {

            Array.prototype.forEach.call(loadItems, function(loadItem, i) {

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'siteEvent',
                    siteEventData: {
                        category: loadItem.Category,
                        action: loadItem.Action,
                        label: loadItem.Label,
                        value: loadItem.Value,
                        nonInteraction: loadItem.NonInteraction
                    }
                });
            });
        }

        var gtmItems = document.querySelectorAll('[data-gtm-event-category]');

        Array.prototype.forEach.call(gtmItems, function(gtmItem, i) {

            var category = gtmItem.getAttribute('data-gtm-event-category');
            var action = gtmItem.getAttribute('data-gtm-event-action');
            var label = gtmItem.getAttribute('data-gtm-event-label');
            var value = gtmItem.getAttribute('data-gtm-event-value');
            var nonInteraction = gtmItem.getAttribute('data-gtm-event-noninteraction');

            if (typeof category === 'undefined' || category === null || category === '') {
                return;
            }

            if (typeof action === 'undefined' || action === null || action === '') {
                return;
            }

            if (typeof label === 'undefined' || label === null || label === '') {
                label = location.pathname;
                if (typeof label === 'undefined' || label === null || label === '') {
                    label = undefined;
                }
            }

            if (typeof value === 'undefined' || value === null || value === '') {
                value = undefined;
            }
            else {
                value = parseInt(value, 10);
                if (isNaN(value)) {
                    value = undefined;
                }
            }

            if (typeof nonInteraction === 'undefined' || nonInteraction === null || nonInteraction === '') {
                nonInteraction = 'false';
            }
            else if (nonInteraction !== 'true' && nonInteraction !== 'false') {
                nonInteraction = 'false';
            }

            onClickOrTap(gtmItem, function(e) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'siteEvent',
                    siteEventData: {
                        category: category,
                        action: action,
                        label: label,
                        value: value,
                        nonInteraction: nonInteraction
                    }
                });
            });
        });

        var gtmVirtualPages = document.querySelectorAll('[data-gtm-vpage-url]');

        Array.prototype.forEach.call(gtmVirtualPages, function(gtmVPage, i) {

            var url = gtmVPage.getAttribute('data-gtm-vpage-url');
            var title = gtmVPage.getAttribute('data-gtm-vpage-title');
            var contentGroup = gtmVPage.getAttribute('data-gtm-vpage-cgroup');

            if (typeof url === 'undefined' || url === null || url === '') {
                return;
            }

            if (typeof title === 'undefined' || title === null || title === '') {
                return;
            }

            onClickOrTap(gtmVPage, function(e) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'siteVirtualPageView',
                    siteVirtualPageViewData: {
                        url: url,
                        title: title,
                        contentGroup: contentGroup
                    }
                });
            });
        });
    });
}());
if (Cookies.get('fontsLoaded') === 'true' || Cookies.get('fontsLoaded') === true) {

    var htmlEl = document.documentElement;
    if (!htmlEl.classList.contains('fonts-loaded')) {
        htmlEl.classList.add('fonts-loaded');
    }
}
else {

    var playfairDisplay400 = new FontFaceObserver('Playfair Display', {weight: 400, style: 'normal'});
    var playfairDisplay400i = new FontFaceObserver('Playfair Display', {weight: 400, style: 'italic'});
    var playfairDisplay700 = new FontFaceObserver('Playfair Display', {weight: 700, style: 'normal'});
    var playfairDisplay700i = new FontFaceObserver('Playfair Display', {weight: 700, style: 'italic'});
    var playfairDisplay900 = new FontFaceObserver('Playfair Display', {weight: 900, style: 'normal'});
    var playfairDisplay900i = new FontFaceObserver('Playfair Display', {weight: 900, style: 'italic'});

    var overpass300 = new FontFaceObserver('Overpass', {weight: 300, style: 'normal'});
    var overpass300i = new FontFaceObserver('Overpass', {weight: 300, style: 'italic'});
    var overpass400 = new FontFaceObserver('Overpass', {weight: 400, style: 'normal'});
    var overpass400i = new FontFaceObserver('Overpass', {weight: 400, style: 'italic'});
    var overpass600 = new FontFaceObserver('Overpass', {weight: 600, style: 'normal'});
    var overpass600i = new FontFaceObserver('Overpass', {weight: 600, style: 'italic'});
    var overpass700 = new FontFaceObserver('Overpass', {weight: 700, style: 'normal'});
    var overpass700i = new FontFaceObserver('Overpass', {weight: 700, style: 'italic'});

    Promise.all([
        playfairDisplay400.load(),
        playfairDisplay400i.load(),
        playfairDisplay700.load(),
        playfairDisplay700i.load(),
        playfairDisplay900.load(),
        playfairDisplay900i.load(),

        overpass300.load(),
        overpass300i.load(),
        overpass400.load(),
        overpass400i.load(),
        overpass600.load(),
        overpass600i.load(),
        overpass700.load(),
        overpass700i.load()
    ])
        .then(function() {
            document.documentElement.classList.add('fonts-loaded');
            Cookies.set('fontsLoaded', true);
        })
        .catch(function (err) {
            document.documentElement.classList.remove('fonts-loaded');
            Cookies.set('fontsLoaded', false);
            console.warn('Some fonts did not load:', err);
        });
}

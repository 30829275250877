;(function () {
    'use strict';


    ready(function() {
        setTimeout(function() {
            var hash = location.hash;
            if (hash[0] === '#') {
                hash = hash.slice(1);
                var hashElement = document.getElementById(hash);
                if (typeof hashElement !== 'undefined' && hashElement !== null) {
                    if (!hashElement.classList.contains('js-accordion-header')) {
                        hash = false;
                    }
                }
                else {
                    hash = false;
                }
            }
            else {
                hash = false;
            }
            initAccordions(hash);
        }, 1);
    });

    var initAccordions = function(hash) {

        var accordions = document.querySelectorAll('.js-accordion');

        Array.prototype.forEach.call(accordions, function(accordion, i) {

            var headers = accordion.querySelectorAll('.js-accordion-header');
            var list = accordion.querySelector('.js-accordion-list');
            var buttonClass = list.getAttribute('data-accordion-button-class');
            var openHeaders = list.getAttribute('data-accordion-open');
            var openPanelIndex = false;
            var openPanelButton = false;

            var expandToggles = accordion.querySelectorAll('.js-accordion-all-expand');
            var collapseToggles = accordion.querySelectorAll('.js-accordion-all-collapse');

            var openHeadersOnLoad = false;
            if (typeof openHeaders !== 'undefined' && openHeaders !== null) {
                openHeadersOnLoad = JSON.parse(openHeaders);
            }

            Array.prototype.forEach.call(headers, function(header, i)
            {
                var headerID = header.getAttribute('ID');
                var headerContent = header.innerHTML;
                var button = document.createElement('button');
                header.classList.remove('js-accordion-header');
                button.classList.add('js-accordion-header');
                button.classList.add(buttonClass);
                button.innerHTML = headerContent;
                header.innerHTML = button.outerHTML;

                if (hash && hash === headerID) {
                    openPanelIndex = i;
                    openPanelButton = button;
                }
            });

            var baOptions = {
                headerClass: '.js-accordion-header',
                panelClass: '.js-accordion-panel',
                panelInnerClass: '.js-accordion-panel-inner',
                hiddenClass: 'is-hidden',
                initializedClass: 'is-initialized',
                openMultiplePanels: true
            };

            if (openHeadersOnLoad !== false) {
                baOptions['openHeadersOnLoad'] = openHeadersOnLoad;
            }
            
            var ba = new BadgerAccordion(list, baOptions);

            if (openPanelIndex === 0 || openPanelIndex && openPanelIndex > 0) {
                ba.open(openPanelIndex);
                if (openPanelButton) {
                    openPanelButton.focus();
                }
            }

            Array.prototype.forEach.call(expandToggles, function(expandToggle, i)
            {
                onClickOrTap(expandToggle, function(e) {
                    e.preventDefault();
                    ba.openAll();
                });
            });

            Array.prototype.forEach.call(collapseToggles, function(collapseToggle, i)
            {
                onClickOrTap(collapseToggle, function(e) {
                    e.preventDefault();
                    ba.closeAll();
                });
            });
        });
    };

}());

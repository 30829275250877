;(function () {
	'use strict';

	var menuToggles = document.querySelectorAll('.js-menu-toggle');
	var menu = document.querySelector('.js-masthead-menu');

	Array.prototype.forEach.call(menuToggles, function(toggle, i) {
		onClickOrTap(toggle, function(e) {
			if (menu.classList) {
				menu.classList.toggle('is-active');
			}

			if (toggle.classList) {
				toggle.classList.toggle('is-open');
			}

			var currentYPosition = window.scrollY;
			if (typeof currentYPosition === 'undefined' || currentYPosition === null) {
				currentYPosition = window.pageYOffset;
			}
			if (currentYPosition < 1) {
				window.scrollTo({top: 1, left: 0, behavior: 'smooth'});
			}
		});
	});
}());

var scrollPos = 0;
var nav = document.querySelector('.SubscribeTagMasthead');

function checkPosition() {
	if (typeof nav !== 'undefined' && nav !== null) {
		var windowY = window.scrollY;
		if(window.innerWidth < 769){
			if (windowY > scrollPos) {
				nav.style.opacity = '1';
			} else {
				nav.style.opacity = '0';
			}
		}
	}
}
function hideMobileTab() {
	if (typeof nav !== 'undefined' && nav !== null) {
		if(window.innerWidth > 768){
			nav.style.opacity = '0';
		}
	}
}

window.addEventListener('scroll', checkPosition);
window.addEventListener('resize', hideMobileTab);
